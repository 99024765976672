<template>
  <div class="changePassword">
    <!-- 顶部导航 -->
    <commonNavBar :title="'修改密码'"></commonNavBar>
    <div
      class="changeInfo"
      v-watermark
    >
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            label="旧密码"
            v-model="oldPassword"
            type="password"
            name="oldPassword"
            placeholder="旧密码"
            :rules="[
              { required: true, message: '请输入旧密码', trigger: 'onChange' },
            ]"
          />
          <van-field
            label="新密码"
            v-model="newPassword"
            type="password"
            name="newPassword"
            placeholder="新密码"
            :rules="[
              { required: true, message: '请输入新密码', trigger: 'onChange' },
              {
                pattern:
                  /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
                message: '密码长度为8-16位,必须包含字母、数字、字符',
                trigger: 'onChange',
              },
              {
                validator: validatorEmojiText,
                message: '请勿使用颜文字!',
                trigger: 'onChange',
              },
            ]"
          />
          <van-field
            label="确认密码"
            v-model="confirmNewPassword"
            type="password"
            name="confirmNewPassword"
            placeholder="确认密码"
            :rules="[
              {
                required: true,
                message: '请输入确认密码',
                trigger: 'onChange',
              },
              { validator, message: '两次密码不一致', trigger: 'onChange' },
            ]"
          />
        </van-cell-group>
        <p class="info">注:密码长度至少8位,需包含字母、数字、符号。</p>
        <van-button
          type="info"
          native-type="submit"
          size="large"
          :loading="loading"
        >保存</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { emojiErrorText } from '@/units/validate'
import { userChangePassword } from '@/api/user'
import { mapMutations, mapActions } from 'vuex'
import { resetRouter } from '@/router/index'

export default {
  name: 'changePassword',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      loading: false

    }
  },
  methods: {
    ...mapMutations('user', ['removeTokenInfo', 'removeUserProfile']),
    ...mapActions('message', ['destroyConnection']),

    //  校验密码是否一致
    validator() {
      return this.newPassword === this.confirmNewPassword
    },
    // 校验是否含有颜文字
    validatorEmojiText(val) {
      return emojiErrorText(val)
    },
    // 提交修改密码
    async onSubmit(val) {
      this.loading = true
      try {
        await userChangePassword(val)
        this.removeTokenInfo()
        this.removeUserProfile()
        this.destroyConnection()
        resetRouter()
        this.loading = false
        this.$router.push('/loginPage')
        this.$toast.success({
          message: '修改成功!',
          duration: 500
        })
      } catch (error) {
        this.loading = false
        if (error?.response?.status === 401) {
          return
        }
        if (error.message.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        if (error.response.data.status === 'E00010013') {
          this.$toast.fail({
            message: '旧密码错误!',
            duration: 500
          })
          return
        }
        this.$toast.fail({
          message: '修改失败!',
          duration: 500
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.changePassword {
  ::v-deep {
    .changeInfo {
      height: calc(100vh - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;

      .info {
        margin-top: 10px;
        color: #aaaaaa;
        font-size: 13px;
        text-indent: 15px;
      }

      .van-button--info {
        position: absolute;
        bottom: 40px;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #00a6f0;
        height: 35px;
      }
    }
  }
}
</style>
