var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changePassword"},[_c('commonNavBar',{attrs:{"title":'修改密码'}}),_c('div',{directives:[{name:"watermark",rawName:"v-watermark"}],staticClass:"changeInfo"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-cell-group',[_c('van-field',{attrs:{"label":"旧密码","type":"password","name":"oldPassword","placeholder":"旧密码","rules":[
            { required: true, message: '请输入旧密码', trigger: 'onChange' },
          ]},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('van-field',{attrs:{"label":"新密码","type":"password","name":"newPassword","placeholder":"新密码","rules":[
            { required: true, message: '请输入新密码', trigger: 'onChange' },
            {
              pattern:
                /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
              message: '密码长度为8-16位,必须包含字母、数字、字符',
              trigger: 'onChange',
            },
            {
              validator: _vm.validatorEmojiText,
              message: '请勿使用颜文字!',
              trigger: 'onChange',
            },
          ]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('van-field',{attrs:{"label":"确认密码","type":"password","name":"confirmNewPassword","placeholder":"确认密码","rules":[
            {
              required: true,
              message: '请输入确认密码',
              trigger: 'onChange',
            },
            { validator: _vm.validator, message: '两次密码不一致', trigger: 'onChange' },
          ]},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1),_c('p',{staticClass:"info"},[_vm._v("注:密码长度至少8位,需包含字母、数字、符号。")]),_c('van-button',{attrs:{"type":"info","native-type":"submit","size":"large","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }